import React from "react";
import "./AboutUs.css";

import Anur from "../../../assets/images/Anur.svg";
import logo from "../../../assets/images/logo.png";
import whoWeAreImg from "../../../assets/images/3721429 1.svg";
import linkedin from "../../../assets/images/LinkedIn icon.svg";
import team from "../../../assets/images/k.svg";
import { useNavigate } from "react-router-dom";
function AboutUs() {
  const navigate = useNavigate();
  return (
    <div className="about-us">
      <div className="who-we-are">
        <h1 className="blue-heading">
          Who <span className="orange-heading"> We</span> Are
        </h1>
        <p className="paras">
          At iLaqa, we aim to bring back the close-knit, supportive feel of a
          village to modern life. Instead of searching far for help with
          everyday tasks, we encourage people to connect with their neighbours
          first.
        </p>
        <p className="paras">
          Our vision is to create neighbourhoods where people know, support, and
          grow together. By fostering local connections, we make life easier,
          more affordable, and fulfilling. iLaqa is about strengthening bonds,
          so everyone feels valued, connected, and part of something bigger
        </p>
        <div className="who-we-are-img">
          <img src={whoWeAreImg} className="who-we-are-page-img"></img>
        </div>
      </div>
      <div className="our-journey who-we-are">
        <h1 className="blue-heading">
          Our <span className="orange-heading"> Journey</span> So Far
        </h1>

        <p className="paras">
          iLaqa started in November 2022 as a passion project, connecting
          neighbours with trusted service providers. It quickly grew, and today,
          we're a registered company with over 3,000 users—our "neighbours." Our
          journey has been fueled by a shared belief in the power of community
          and the dedication of a growing, passionate team. As we continue to
          develop new ways to meet the evolving needs and trust of our
          neighbourhood, we remain committed to our original mission: to create
          a connected, supportive community where everyone can
          <span className="opacity-reduced-text">thrive together.</span>
          <button
            className="read-full"
            style={{ padding: 0 }}
            onClick={() => {
              navigate("ourjourney");
            }}
          >
            Read Full
          </button>
        </p>
      </div>
      <div className="leadership">
        <h1 className="blue-heading">
          The Leadership team in <span className="orange-heading"> iLaqa</span>
        </h1>
        <div
          className="A-container"
          style={{ gap: "10px", paddingInline: "10px" }}
        >
          <div
            className="A-container-right"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={Anur} className="A-image"></img>
            <p
              className="t-container-left-position"
              style={{
                fontWeight: "700",
                fontSize: "12px",
                marginTop: "20px",
                color: "#0C356A",
                marginBottom: 0,
              }}
            >
              Connect on{" "}
            </p>
            <a
              href="https://www.linkedin.com/in/anur-puniyani-32a22a21/"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <img
                src={linkedin}
                style={{ width: "20px", height: "20px" }}
              ></img>
            </a>
          </div>
          <div className="A-container-left">
            <p className="t-container-left-name">Anur Ram Puniyani</p>
            <p className="t-container-left-position">
              Founder & CEO , iLaqa Technologies Pvt Ltd.
            </p>
            <p
              className="t-container-left-description"
              style={{ marginBlock: "20px" }}
            >
              He is the visionary behind iLaqa, starting this journey with a
              passion for bringing people closer together. As our Founder and
              CEO,Anur is the driving force behind our mission, sparking ideas
              and steering the ship with enthusiasm.
            </p>
            <div className="A-read-more" style={{ display: "flex" }}>
              <button
                onClick={() => {
                  navigate("ourceo");
                }}
                className="A-read-more-button"
              >
                Read More
              </button>
            </div>
          </div>
        </div>
        <div className="t-container">
          <div className="t-container-left">
            <p className="t-container-left-name">Tanmay Puniyani</p>
            <p className="t-container-left-position">
              Co-founder , iLaqa <br></br>Technologies Pvt Ltd.
            </p>
            <p
              className="t-container-left-description"
              style={{ textAlign: "center" }}
            >
              She is the heart and soul of iLaqa, overseeing every neighbour and
              service with her big-hearted, creative touch. As co-founder, she’s
              deeply involved in the daily operations, ensuring everything runs
              smoothly and personally connecting with our 3,000+ users. Tanmay’s
              dedication and warmth make her a cherished figure in our
              community, driving our mission forward with passion and care.
            </p>
          </div>
        </div>
      </div>
      <div className="team">
        <h1
          className="blue-heading"
          style={{
            fontSize: "20px",
            textAlign: "center",
            marginBottom: 0,
            lineHeight: "15px",
          }}
        >
          Our Fabulous <span className="orange-heading"> Team </span>Turning
          iLaqa into a Reality
        </h1>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={team}
            style={{
              marginBlock: "25px",
              objectFit: "contain",
              width: "100vw",
            }}
            className="team-image"
          ></img>
        </div>
      </div>
      <div className="about-us-contact-us">
        <h1 className="about-us-contact-us-text">Contact Us</h1>
        <div className="about-us-contact-us-section">
          <div className="about-us-contact-us-left">
            <div>
              <span style={{ fontWeight: 700 }}>Official Number</span> :
              8008580248
            </div>
            <div>
              <span style={{ fontWeight: 700 }}>Official Email</span> -
              Heyneighbour@iLaqa.com
            </div>
            <div>
              <span style={{ fontWeight: 700 }}>Official Address</span>: 1102
              Building Number 5 , MHADA Deluxe Apartment , Rambaug, Powai ,
              Mumbai, 400076
            </div>
          </div>
          <div className="about-us-contact-us-right">
            <img src={logo} style={{ height: "60px", width: "60px" }}></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
